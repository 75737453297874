import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState, Answer, SurveyAnswer } from './types';

const app = createSlice({
  name: 'app',
  initialState: {
    firstName: '',
    lastName: '',
    email: '',
    userId: '',
    sessionId: '',
    preTests: {
      0: {
        answers: {},
      },
      1: {
        answers: {},
      },
    },
    tests: {
      0: {
        answers: {},
      },
      1: {
        answers: {},
      },
    },
    surveys: {
      0: {
        answers: {},
      },
      1: {
        answers: {},
      },
    },
  } as AppState,
  reducers: {
    setFirstName: (state, action: PayloadAction<string>) => {
      state.firstName = action.payload;
    },
    setLastName: (state, action: PayloadAction<string>) => {
      state.lastName = action.payload;
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setUserId: (state, action: PayloadAction<string>) => {
      state.userId = action.payload;
    },
    setSessionId: (state, action: PayloadAction<string>) => {
      state.sessionId = action.payload;
    },
    setPretestAnswer: (state, action: PayloadAction<Answer>) => {
      state.preTests[action.payload.preTest].answers[
        action.payload.questionId
      ] = action.payload.choiceId;
    },
    setTestAnswer: (state, action: PayloadAction<Answer>) => {
      state.tests[action.payload.preTest].answers[action.payload.questionId] =
        action.payload.choiceId;
    },
    setSurveyAnswer: (state, action: PayloadAction<SurveyAnswer>) => {
      state.surveys[action.payload.testIndex].answers[
        action.payload.questionId
      ] = action.payload.answer;
    },
  },
});

export default app;
