import React from 'react';

interface Member {
  name: string;
  titles: string;
  picture?: string;
}

interface FacultyProps {
  label: string;
  members: Member[];
}

const Faculty: React.FC<FacultyProps> = (props) => {
  return (
    <div style={{ marginBottom: 20 }}>
      <h5>{props.label}</h5>
      <div className="d-flex flex-wrap">
        {props.members.map((member) => {
          return (
            <div className="member" key={member.picture}>
              <div className="">
                <img
                  src={`/images/${
                    member.picture ? member.picture + '.jpg' : 'silhouette.png'
                  }`}
                  className="rounded-circle"
                  alt={member.name}
                />
              </div>
              <div className="name">
                {member.name}, {member.titles}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Faculty;
