import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from '@redux-requests/react';
import { Link } from 'react-router-dom';
import { getTests } from '../store/requests';
import Test from '../components/Test';
import Faculty from '../components/Faculty';

const intlSpeakers = [
  {
    name: 'Nancy M. Holekamp',
    titles: 'MD',
    picture: 'holekamp',
  },
  {
    name: 'Rishi P. Singh',
    titles: 'MD',
    picture: 'singh',
  },
];

const planningCommittee = [
  {
    name: 'Jason Noble',
    titles: 'MD, FRCSC, DABO (Chair)',
    picture: 'noble',
  },
  {
    name: 'Varun Chaudhary',
    titles: 'MD, FRCS(C) (COS Representative)',
    picture: 'chaudhary',
  },
  {
    name: 'Amber Sheikh',
    titles: 'BSc (Hons), BA, MD, FRCSC',
    picture: 'sheikh',
  },
];

const survey = [
  {
    question:
      'Please indicate whether you think the following learning objectives were met:',
    type: 'container' as const,
    subQuestions: [
      {
        question:
          'Appraise new evidence evaluating how fluid can influence long-term visual outcomes for patients with nAMD',
        type: 'choice' as const,
        choices: ['Yes', 'No'],
      },
      {
        question:
          'Describe up-to-date treatment algorithms which can be applied to clinical practice',
        type: 'choice' as const,
        choices: ['Yes', 'No'],
      },
      {
        question:
          'Evaluate new evidence on how to optimize the management of RVO',
        type: 'choice' as const,
        choices: ['Yes', 'No'],
      },
    ],
  },
  {
    question: 'Was the information in the online program presented clearly?',
    type: 'choice' as const,
    choices: ['Yes', 'No'],
  },
  {
    question:
      'Did the online program enhance your knowledge on the subject matter?',
    type: 'choice' as const,
    choices: ['Yes', 'No'],
  },
  {
    question: 'Did you find the online program was relevant to your practice?',
    type: 'choice' as const,
    choices: ['Yes', 'No'],
  },
  {
    question: 'Did the online program meet the following CanMEDs Roles?:',
    type: 'container' as const,
    subQuestions: [
      {
        question: 'Medical Expert',
        type: 'choice' as const,
        choices: ['Yes', 'No'],
      },
      {
        question: 'Scholar',
        type: 'choice' as const,
        choices: ['Yes', 'No'],
      },
    ],
  },
  {
    question: 'Did you perceive any bias in the webinar presentation?',
    choices: ['Yes', 'No'],
    type: 'choice' as const,
    subQuestions: [
      {
        question: 'If yes, please describe:',
        type: 'textarea' as const,
      },
    ],
  },
  {
    question:
      'What impact do you think learning gained from this activity will have on your practice? (select all that apply)',
    type: 'multiple' as const,
    choices: [
      'I will incorporate up-to-date treatment algorithms into my clinical practice',
      'I will incorporate the latest evidence on the management of RVO into my clinical decisions',
      'I will incorporate the latest evidence on how fluid can influence long-term visual outcomes for patients with nAMD',
      'Other',
      'No impact',
    ],
  },
];

const RetinaWarPage: React.FC<{}> = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTests());
  }, [dispatch]);

  const tests = useQuery({ type: getTests.type });
  if (tests.loading || !tests.data) {
    return <div>'Loading...'</div>;
  }

  return (
    <>
      <div className="row">
        <div className="col" style={{ textAlign: 'right', paddingBottom: 8 }}>
          <Link to="/">Back to homepage</Link>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <img src="/images/riw-header.jpg" alt="" />
        </div>
      </div>
      <div className="row" style={{ marginTop: 30 }}>
        <div className="col">
          <h4>Learning Objectives</h4>
          <ul>
            <li>
              Appraise new evidence evaluating how fluid can influence long-term
              visual outcomes for patients with nAMD
            </li>
            <li>
              Describe up-to-date treatment algorithms which can be applied to
              clinical practice
            </li>
            <li>
              Evaluate new evidence on how to optimize the management of RVO
            </li>
          </ul>

          <h4>Faculty</h4>
          <Faculty label="International Speakers" members={intlSpeakers} />
          <Faculty label="Planning Committee" members={planningCommittee} />

          <Test
            questions={tests.data[0].questions}
            testNum={0}
            testId={tests.data[0].id}
            videoFilename="Retina_Infinity_War-June27_F4_1"
            surveyQuestions={survey}
          />

          <div className="statement">
            <p>
              This session was co-developed by the Canadian Ophthalmological
              Society and Bayer and was planned to achieve scientific
              objectivity and balance.
            </p>
            <p>
              This activity is an Accredited Self-Assessment Program (Section 3)
              as defined by the Maintenance of Certification Program of the
              Royal College of Physicians and Surgeons of Canada, and approved
              by the Canadian Ophthalmological Society. You may claim a maximum
              of 1.5 hours.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default RetinaWarPage;
