import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from '@redux-requests/react';
import { Link } from 'react-router-dom';
import { getTests } from '../store/requests';
import Test from '../components/Test';
import Faculty from '../components/Faculty';

const intlSpeakers = [
  {
    name: 'Neil Bressler',
    titles: 'MD',
    picture: 'bressler',
  },
  {
    name: 'Phil Rosenfeld',
    titles: 'MD',
    picture: 'rosenfeld',
  },
  {
    name: 'Carl Regillo',
    titles: 'MD',
    picture: 'regillo',
  },
  {
    name: 'Szilard Kiss',
    titles: 'MD',
    picture: 'kiss',
  },
  {
    name: 'Rajeev Muni',
    titles: 'MD',
    picture: 'muni',
  },
  {
    name: 'David Sarraf',
    titles: 'MD',
    picture: 'sarraf',
  },
  {
    name: 'Robyn Guymer',
    titles: 'MD',
    picture: 'guymer',
  },
];

const planningCommittee = [
  {
    name: 'Peter Kertes',
    titles: 'MD (Chair)',
    picture: 'kertes',
  },
  {
    name: 'Varun Chaudhary',
    titles: 'MD, FRCS(C) (COS Representative)',
    picture: 'chaudhary',
  },
  {
    name: 'Tim Hillson',
    titles: 'MD',
    picture: 'hillson',
  },
  {
    name: 'David Maberley',
    titles: 'MD',
    picture: 'maberley',
  },
];

const survey = [
  {
    question:
      'Please indicate whether you think the following learning objectives were met:',
    type: 'container' as const,
    subQuestions: [
      {
        question:
          'Explain the significance and impact of recent clinical developments in AMD: results from the FLUID study and latest insights on subclinical lesions',
        type: 'choice' as const,
        choices: ['Yes', 'No'],
      },
      {
        question:
          'Explain the significance and impact of key clinical developments in DME: lessons from DRCR.net and use of aqueous cytokines as biomarkers of treatment response',
        type: 'choice' as const,
        choices: ['Yes', 'No'],
      },
      {
        question:
          'Describe the different properties and relative advantages of new agents in development for AMD and DME',
        type: 'choice' as const,
        choices: ['Yes', 'No'],
      },
      {
        question: 'Discuss the impact of COVID-19 on clinical practice',
        type: 'choice' as const,
        choices: ['Yes', 'No'],
      },
    ],
  },
  {
    question: 'Was the information in the online program presented clearly?',
    type: 'choice' as const,
    choices: ['Yes', 'No'],
  },
  {
    question:
      'Did the online program enhance your knowledge on the subject matter?',
    type: 'choice' as const,
    choices: ['Yes', 'No'],
  },
  {
    question: 'Did you find the online program was relevant to your practice?',
    type: 'choice' as const,
    choices: ['Yes', 'No'],
  },
  {
    question: 'Did the online program meet the following CanMEDs Roles?:',
    type: 'container' as const,
    subQuestions: [
      {
        question: 'Medical Expert',
        type: 'choice' as const,
        choices: ['Yes', 'No'],
      },
      {
        question: 'Scholar',
        type: 'choice' as const,
        choices: ['Yes', 'No'],
      },
    ],
  },
  {
    question: 'Did you perceive any bias in the webinar presentation?',
    choices: ['Yes', 'No'],
    type: 'choice' as const,
    subQuestions: [
      {
        question: 'If yes, please describe:',
        type: 'textarea' as const,
      },
    ],
  },
  {
    question:
      'What impact do you think learning gained from this activity will have on your practice? (select all that apply)',
    type: 'multiple' as const,
    choices: [
      'I will incorporate the latest evidence in AMD in my clinical practice',
      'I will consider the latest insights on subclinical lesions',
      'I will incorporate the latest evidence in DME in my clinical practice',
      'I will consider the use of cytokines as biomarkers of treatment response',
      'I will take into consideration the different properties and relative advantages of new agents in development for AMD ',
      'I will take into consideration the different properties and relative advantages of new agents in development for DME ',
      'I will consider the impact of COVID-19 on my practice interaction with patients in my decision-making process',
      'Other',
      'No impact',
    ],
  },
];

const AmdDmePage: React.FC<{}> = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTests());
  }, [dispatch]);

  const tests = useQuery({ type: getTests.type });
  if (tests.loading || !tests.data) {
    return <div>'Loading...'</div>;
  }

  return (
    <>
      <div className="row">
        <div className="col" style={{ textAlign: 'right', paddingBottom: 8 }}>
          <Link to="/">Back to homepage</Link>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <img src="/images/amd-dme-header.jpg" alt="" />
        </div>
      </div>
      <div className="row" style={{ marginTop: 30 }}>
        <div className="col">
          <h4>Learning Objectives</h4>
          <ul>
            <li>
              Explain the significance and impact of recent clinical
              developments in AMD: results from the FLUID study and latest
              insights on subclinical lesions
            </li>
            <li>
              Explain the significance and impact of key clinical developments
              in DME: lessons from DRCR.net and use of aqueous cytokines as
              biomarkers of treatment response
            </li>
            <li>
              Describe the different properties and relative advantages of new
              agents in development for AMD and DME
            </li>
            <li>Discuss the impact of COVID-19 on clinical practice</li>
          </ul>

          <h4>Faculty</h4>
          <Faculty label="International Speakers" members={intlSpeakers} />
          <Faculty
            label="Scientific Planning Committee"
            members={planningCommittee}
          />

          <Test
            questions={tests.data[1].questions}
            testNum={1}
            testId={tests.data[1].id}
            videoFilename="AMD_DME-Fluid_for_Thought-June28_F4_1"
            surveyQuestions={survey}
          />

          <div className="statement">
            <p>
              This activity is an Accredited Self-Assessment Program (Section 3)
              as defined by the Maintenance of Certification Program of the
              Royal College of Physicians and Surgeons of Canada, and approved
              by the Canadian Ophthalmological Society. You may claim a maximum
              of 1.5 hours.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default AmdDmePage;
