import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { v4 as uuid } from 'uuid';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import IndexPage from './pages/IndexPage';
import RetinaWarPage from './pages/RetinaWarPage';
import AmdDmePage from './pages/AmdDmePage';
import app from './store/app';

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    const sessionId = uuid();
    dispatch(app.actions.setSessionId(sessionId));
  }, []);

  return (
    <div className="app">
      <div className="container">
        <Switch>
          <Route exact path="/" component={IndexPage} />
          <Route exact path="/retina-infinity-war" component={RetinaWarPage} />
          <Route exact path="/amd-dme-report" component={AmdDmePage} />
        </Switch>
      </div>
    </div>
  );
}

export default App;
