import React from 'react';
import { Link } from 'react-router-dom';

const IndexPage: React.FC<{}> = () => {
  return (
    <>
      <div className="row">
        <div className="col">
          <img src="/images/header.jpg" alt="" />
        </div>
      </div>
      <div className="row" style={{ marginTop: 30 }}>
        <div className="col">
          <h3>
            Join us for accredited on-demand learning activities featuring two
            Symposia from the 2020 COS Virtual Annual Meeting
          </h3>
          <p>
            The Canadian Ophthalmological Society is pleased to provide
            ophthalmologists with two 90-minute accredited on-demand learning
            activities featuring two symposia from the 2020 COS Annual Meeting.
          </p>
          <p>
            To meet the criteria for accreditation, you will be asked to
            complete a pre-test and a post-test. In addition, you will be asked
            to complete an evaluation survey following the activity.
            Certificates of Participation will be available for download. Under
            Section 3 of the Maintenance of Certification Program of the Royal
            College of Physicians and Surgeons of Canada, you may claim a
            maximum of 1.5 hours each for the completion of the two learning
            activities (a total of 3 hours).
          </p>
          <p>
            Click on the symposia images below to complete either online module.
            Please set aside 90&nbsp;minutes to complete each module as the
            modules have each been designed to be completed in one sitting.
          </p>
        </div>
      </div>
      <div className="row" style={{ marginTop: 30 }}>
        <div className="col">
          <Link to="/retina-infinity-war">
            <img src="/images/riw.jpg" alt="Retina Infinity War" />
          </Link>
        </div>
        <div className="col">
          <Link to="/amd-dme-report">
            <img src="/images/amd-dme.jpg" alt="AMD/DME" />
          </Link>
        </div>
      </div>
    </>
  );
};

export default IndexPage;
