import { StoreState } from './types';

export const getPretests = (state: StoreState) => state.app.preTests;

export const getTests = (state: StoreState) => state.app.tests;

export const getSurveys = (state: StoreState) => state.app.surveys;

export const getUserId = (state: StoreState) => state.app.userId;

export const getSessionId = (state: StoreState) => state.app.sessionId;
