import {
  configureStore,
  combineReducers,
  getDefaultMiddleware,
} from '@reduxjs/toolkit';
import axios from 'axios';
import { handleRequests } from '@redux-requests/core';
import { createDriver } from '@redux-requests/axios';
import appSlice from './store/app';

const axiosInstance = axios.create({
  // baseURL: `http://173.209.57.134:3001/api`,
  // baseURL: `http://cos2020symposia.com/api`,
  baseURL: `/api`,
  headers: {
    Accept: 'application/json',
  },
});

const { requestsReducer, requestsMiddleware } = handleRequests({
  driver: createDriver(axiosInstance),
});

const store = configureStore({
  reducer: combineReducers({
    app: appSlice.reducer,
    requests: requestsReducer,
  }),
  middleware: [
    ...getDefaultMiddleware({
      serializableCheck: false,
    }),
    ...requestsMiddleware,
  ],
});

export default store;
