import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import app from '../store/app';
import { Question as QuestionType } from '../types';

interface QuestionProps {
  question: QuestionType;
  testId: number;
  isPretest: boolean;
}

const Question: React.FC<QuestionProps> = ({ question, testId, isPretest }) => {
  const dispatch = useDispatch();
  const [value, setvalue] = useState<number | null>(null);
  return (
    <>
      <div style={{ marginTop: 20 }}>{question.text}</div>
      {question.choices.map((choice) => {
        return (
          <div key={choice.id}>
            <label>
              <input
                type="radio"
                name={`question${question.id}`}
                value={choice.id}
                checked={value === choice.id}
                onChange={() => {
                  setvalue(choice.id);
                  dispatch(
                    app.actions[
                      isPretest ? 'setPretestAnswer' : 'setTestAnswer'
                    ]({
                      preTest: testId,
                      questionId: question.id,
                      choiceId: choice.id,
                    }),
                  );
                }}
              />
              &nbsp;{choice.text}
            </label>
          </div>
        );
      })}
    </>
  );
};

export default Question;
